.legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px; /* Espacio entre leyendas */
}

.circle {
    width: 10px;        /* Ancho del círculo */
    height: 10px;       /* Alto del círculo */
    border-radius: 50%; /* Hace que el div sea un círculo */
    margin-right: 8px;  /* Espacio entre el círculo y la etiqueta */
}
