:root {
    --primary-light: #000000;
    --primary-main: #fbff00;
    --primary-dark: #000000;
    --primary-contrastText: #000000;
  
    --secondary-main: #000000;
    --secondary-light: #000000;
    --secondary-dark: #000000;
    --secondary-contrastText: #000000;
}
[data-theme= "light"] {
   
    --primary-light: #000000;
    --primary-main: #000000;
    --primary-dark: #000000;
    --primary-contrast: #000000;
  
    --secondary-main: #000000;
    --secondary-light: #000000;
    --secondary-dark: #000000;
    --secondary-contrastText: #000000;
}
[data-theme= "dark"] {
   
    --primary-light: #000000;
    --primary-main: #000000;
    --primary-dark: #000000;
    --primary-contrastText: #000000;
  
    --secondary-main: #000000;
    --secondary-light: #000000;
    --secondary-dark: #000000;
    --secondary-contrastText: #000000;
}